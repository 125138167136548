// Style page Contact

	.intro-contact .intro-texte {
		@media (max-width: ($large)) {
			align-items: flex-end;
			padding-bottom: 4rem;
		}
	}

	.contact-presentation {
		.container {
			display: flex;
			align-items: center;

			@media (max-width: ($small)) {
				flex-direction: column;
			}
		}

		.logo-anaf {
			width: 20rem;
			height: 13.9rem;
			margin-right: 5rem;
			fill: $anthracite;

			@media (max-width: ($small)) {
				margin: 0 0 2rem;
			}
		}

		.content-txt-adress-phone svg {
			margin-right: .3rem;
			vertical-align: middle;
			fill: $anthracite;
		}

		.text-wrapper {
			p:last-of-type {
				margin-top: 2rem;
				letter-spacing: .1rem;
				font-size: 1.4rem;
			}

			ul {
				margin: 0;
				font-size: 1.4rem;
				list-style-type: circle;
			}
		}
	}

	.contact-formulaire {
		.container-small {
			margin-top: 4rem;
		}

		.error {
			display: block;
			margin: 1rem 0 0;
			font-weight: 400;
			color: $error;
		}

		.form-message {
			display: block;
			margin: 0 0 1.5rem;
			padding: 1.5rem 2rem;
			color: $white;

			&.form-erreur {
				background-color: $error;
			}

			&.form-notice {
				background-color: $success;
			}
		}
	}