
	// Styles pour les plugins JS

		// bLazy.js – A lazyload image script
	    
		    .b-lazy {
				max-width: 100%;
				opacity: 0;
		        transition: opacity .2s ease;  
			}

		    .b-lazy.b-loaded {
		        opacity: 1;
		    }