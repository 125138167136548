
	// Styles de formulaire
		
		// Reset
			
			input[type=number] {
				-moz-appearance: textfield;
			}

			input[type=number]::-webkit-inner-spin-button, 
			input[type=number]::-webkit-outer-spin-button { 
				-webkit-appearance: none; 
				margin: 0; 
			}

			input:-webkit-autofill { // Background Yellow on Chrome
			    -webkit-box-shadow: 0 0 0 3rem white inset;
			}

			::-ms-clear {
		        display: none;
		  	}

		  	::-ms-reveal {
		        display: none;
		  	}

		  	input[type=text], textarea {   
		    	-webkit-appearance: none;
		   	 	-moz-appearance: none;
		    	appearance: none;
		    }

		// Placeholder

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: $black;
			}

			::-moz-placeholder { /* Firefox 19+ */
				color: $black;
			}

			:-ms-input-placeholder { /* IE 10+ */
				color: $black;
			}

			:-moz-placeholder { /* Firefox 18- */
				color: $black;
			}

		// Custom

			.form-label, .form-text {
				width: 100%;
			}

			.form-label {
				margin-bottom: 1rem;
			}

			.form-text {
				padding: 1.5rem;
				font-weight: 300;
				outline: none;
				border: .1rem solid rgba($gris-bleu, .5);
				box-shadow: none;
				background-color: transparent;
				transition: background-color .3s ease-in-out;

				&:focus {
					background-color: $white;
				}
			}

			.form-item {
				margin-bottom: 1.5rem;
			}

			.form-item-submit {
				display: flex;
			    align-items: flex-start;
			    justify-content: flex-end;
			    margin: 4rem 0 0;

			    @media (max-width: $tiny) {
			    	flex-direction: column;
			    	align-items: center;
			    	justify-content: flex-start;
			    }

			    .basic-btn {
			    	margin: .2rem 0 0 2rem;

			    	@media (max-width: $tiny) {
			    		margin: 1rem 0 0;
			    	}
			    }
			}

			// Resize Google captcha
			#rc-imageselect, .g-recaptcha {
				transform-origin: 0 0 !important;

				@media (max-width: $tiny-plus) {
					transform: scale(0.8) !important;
				}

				@media (max-width: $tiny) {
					transform-origin: 50% 0 !important;
				}
			}

