
  // Accueil

	.home-actus {
		.news-single {
			background-color: $white;

			.news-single-pic {
				width: 40%;
				@extend %bg-cover;

				@media (max-width: ($medium)) {
					width: 100%;
					height: 25rem;
				}
			}

			.news-single-content {
				width: 60%;
				padding: 5rem 3rem;

				@media (max-width: ($medium)) {
					width: 100%;
					padding: 3rem 2rem;
				}
			}

			h3 {
				position: relative;

				&:after {
					position: absolute;
					left: 0;
					bottom: -1.4rem;
					height: .1rem;
					width: 8rem;
					content: '';
					background-color: $bleu-anaf;
				}
			}

			.news-single-date {
				margin-bottom: 3rem;
				font-size: 1.4rem;
				letter-spacing: .1rem;
				text-transform: uppercase;
			}
		}

		.basic-btn {
			margin-top: 4rem;

			@media (max-width: ($small)) {
				margin-top: 3rem;
			}
		}
	}
