// Style actualités multi et single

	.news-content {
		.news-single {
			margin-bottom: 3rem;
			background-color: $gris-clair;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.news-single-pic {
			width: 40%;
			@extend %bg-cover;

			@media (max-width: ($medium)) {
				width: 100%;
				height: 25rem;
			}
		}

		.news-single-content {
			width: 60%;
			padding: 5rem 3rem;

			@media (max-width: ($medium)) {
				width: 100%;
				padding: 3rem 2rem;
			}
		}

		h2 {
			position: relative;

			&:after {
				position: absolute;
				left: 0;
				bottom: -1.4rem;
				height: .1rem;
				width: 8rem;
				content: '';
				background-color: $bleu-anaf;
			}
		}

		.news-single-date {
			margin-bottom: 3rem;
			font-size: 1.4rem;
			letter-spacing: .1rem;
			text-transform: uppercase;
		}
	}

	.section-news-single {
		img {
			width: 100%;
			height: auto;
			margin: 3rem 0;
		}
	}

	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;

		a, .current {
			width: 3.9rem;
			height: 3.9rem;
			margin: 0 .2rem;
			padding: 1rem;
			text-align: center;
			text-decoration: none;
		}

		a {
			display: inline-block;
			color: $gris-bleu;
			border: .1rem solid $gris-bleu;
			transition: all .3s ease-in-out;

			&:hover {
				color: $white;
				background-color: $gris-bleu;
			}
		}

		.current {
			color: $white;
			background-color: $bleu-anaf;
		}
	}