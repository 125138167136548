
	// Navigation

		header {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 5.9rem;
			padding: 1.5rem 0;
			z-index: 3;
			transition: all .3s $ease;

			@media (max-width: ($large - 1)) {
				padding: 1rem 0;
			}

			&.is-scroll {
				padding: 1rem 0;
				background-color: $anthracite;

				.link-home {
					height: 2.8rem;
					margin: .55rem 0;
				}

				.logo-anaf {
					.logo-sub {
						opacity: 0;
					}
				}

				.nav-link, .hamburger, .hamburger .hamburger-label {
					color: $bleu-nav;

					&:before, &:after {
						background-color: rgba($bleu-nav, .5);
					}
				}

				.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
					background-color: $bleu-nav;
				}
			}

			&.is-mobile-nav-open {
				.links-wrapper {
					pointer-events: auto;
					opacity: 1;
				}
			}

			.navigation {
				align-items: flex-start;
			}

			.link-home {
				transition: all .3s $ease;
			}

			.logo-anaf {
				position: relative;
				width: 16rem;
				height: 11rem;
				fill: $white;
				z-index: 1;
				transition: all .3s ease-in-out;

				path {
					transition: opacity .3s ease-in-out;
				}

				@media (max-width: ($large - 1)) {
					width: 12rem;
					height: 8.3rem;
				}
			}

			.links-wrapper {
				margin-left: auto;
				margin-right: -1.5rem;
				transition: opacity .2s ease-out;

				@media (max-width: ($large - 1)) {
					position: absolute;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					top: -1.5rem;
				    left: 0;
				    right: 0;
				    bottom: 0;
				    height: 100vh;
				    pointer-events: none;
				    opacity: 0;
					background-color: rgba($black, .9);
				}
			}

			.nav-link {
				position: relative;
				display: inline-block;
				padding: 1rem 1.5rem;
				letter-spacing: .2rem;
				text-decoration: none;
				text-transform: uppercase;
				color: $white;
				transition: color .3s $ease;

				@media (max-width: ($large - 1)) {
					color: $bleu-nav;
				}

				&:before, &:after {
					position: absolute;
					width: 0;
				    height: .1rem;
					content: '';
					background-color: rgba($white, .5);
					transition: all .3s $ease;

					@media (max-width: ($large - 1)) {
						background-color: rgba($bleu-nav, .5);
					}
				}

				&:before {
					top: .5rem;
				    left: 1.5rem;
				}

				&:after {
					right: 1.5rem;
					bottom: .5rem;
				}

				&:hover, &.is-active {
					&:before, &:after {
						width: calc(100% - 3rem);
					}
				}			
			}

			.hamburger {
				display: flex;
				align-items: center;
				margin-left: auto;
				padding: 1rem 0 1rem 2rem;
				outline: none;
				z-index: 1;

				@media (min-width: $large) { // >= 1024px
					display: none;
				}
				
				.hamburger-label {
					margin-right: 1rem;
				    letter-spacing: .2rem;
					text-decoration: none;
					text-transform: uppercase;
					color: $white;
					transition: color .3s $ease;
				}
			}
		}