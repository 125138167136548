
	// Pied de page

	footer {
		font-size: 1.3rem;
		background-color: $anthracite;
	}

	.footer-top {
		padding: 3rem 0;
		color: rgba($gris-clair, .6);

		.container {
			display: flex;

			@media (max-width: ($medium)) {
				flex-direction: column;
			}
		}

		.contact-wrapper {
			@media (min-width: ($medium + 1)) {
				max-width: 31rem;
			}

			p:not(.title-footer) {
				display: block;
				margin: 0 0 1rem;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		.contact-who p:not(.title-footer) {
			line-height: 2rem;
		}

		.contact-nav, .contact-infos {
			@media (min-width: ($medium + 1)) {
				text-align: right;
			}
		}

		.contact-nav {
			@media (min-width: ($medium + 1)) {
				margin-left: auto;
				padding: 0 3rem;
			}

			@media (max-width: ($medium)) {
				padding: 2rem 0;
			}

			a {
				padding-right: 1rem;

				@media (max-width: ($medium)) {
					padding: 0 0 0 1rem;
				}
			}
		}

		.title-footer {
			display: inline-block;
			position: relative;
			margin-bottom: 2rem;
			font: 300 2rem $font-stack-serif;
			letter-spacing: .1rem;
			color: $gris-clair;

			&:after {
				position: absolute;
				left: 0;
				bottom: -.5rem;
				height: .1rem;
				width: 100%;
				content: '';
				background-color: $gris-clair;
			}
		}

		a:not(.basic-btn) {
			text-decoration: none;
			color: $bleu-nav;
			transition: color .3s ease-in-out;

			&:hover {
				color: $bleu-clair;
			}
		}

		.basic-btn {
			display: inline-block;
			margin-top: 1.5rem;
			font-size: 1.6rem;
			border-top-color: $bleu-nav;
        	border-bottom-color: $bleu-nav;
        	color: $bleu-nav;

        	&:hover {
        		color: $anthracite;
        		background-color: $bleu-nav;
        	}
		}
	}

	.footer-credits {
		padding: 1rem 0;
		color: $gris-bleu;
		background-color: $black;

		.container {
			align-items: center;
			justify-content: space-between;

			@media (max-width: ($medium)) {
				flex-direction: column;
				justify-content: center;
				text-align: center;
			}
		}

		a {
			text-decoration: none;
			color: $bleu-anaf;
			transition: color .3s ease-in-out;

			@media (min-width: ($medium + 1)) {
				padding-left: 1.5rem;
			}

			&:hover {
				color: $bleu-nav;

				svg {
					fill: $bleu-clair;
				}
			}
		}

		.footer-credits-right {
			display: flex;
			align-items: center;

			@media (max-width: ($medium)) {
				flex-direction: column;

				a:first-of-type {
					padding: .5rem 0;
				}
			}
		}

		svg {
			fill: $bleu-nav;
			transition: fill .3s ease-in-out;
		}

		.btn-top {
			@media (max-width: ($medium)) {
				position: fixed;
				right: 0;
				bottom: 0;
				padding: 1rem;
				background-color: $bleu-anaf;
				transition: background-color .3s ease-in-out;

				svg {
					fill: $white;
				}

				&:hover {
					background-color: $bleu-nav;

					svg {
						fill: $white;
					}
				}
			}
		}
	}