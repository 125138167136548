
	// Fonts (Polices de caractère)

		//// SLICK

			@font-face {
				font-family: 'slick';
			    src: url('../fonts/slick/slick.woff2') format('woff2'),
			    	 url('../fonts/slick/slick.woff') format('woff'),
			         url('../fonts/slick/slick.ttf') format('truetype'),
					 url('../fonts/slick/slick.svg#Slick') format('svg');
			}

	// Bodoni

			@font-face {
			    font-family: 'Bodoni';
			    src: url('../fonts/Bodoni/BodoniFLF-Roman.woff2') format('woff2'),
			         url('../fonts/Bodoni/BodoniFLF-Roman.woff') format('woff'),
			         url('../fonts/Bodoni/BodoniFLF-Roman.ttf') format('truetype'),
					 url('../fonts/Bodoni/BodoniFLF-Roman.svg#BodoniRoman') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Bodoni';
			    src: url('../fonts/Bodoni/BodoniFLF-Bold.woff2') format('woff2'),
			         url('../fonts/Bodoni/BodoniFLF-Bold.woff') format('woff'),
			         url('../fonts/Bodoni/BodoniFLF-Bold.ttf') format('truetype'),
					 url('../fonts/Bodoni/BodoniFLF-Bold.svg#BodoniBold') format('svg');
			    font-weight: 400;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Bodoni';
			    src: url('../fonts/Bodoni/BodoniFLF-Italic.woff2') format('woff2'),
			         url('../fonts/Bodoni/BodoniFLF-Italic.woff') format('woff'),
			         url('../fonts/Bodoni/BodoniFLF-Italic.ttf') format('truetype'),
					 url('../fonts/Bodoni/BodoniFLF-Italic.svg#BodoniItalic') format('svg');
			    font-weight: 300;
			    font-style: italic;
			}

	// Montserrat

			@font-face {
			    font-family: 'Montserrat';
			    src: url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
			         url('../fonts/Montserrat/Montserrat-Light.woff') format('woff'),
			         url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype'),
					 url('../fonts/Montserrat/Montserrat-Light.svg#MontserratLight') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Montserrat';
			    src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
			         url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
			         url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
					 url('../fonts/Montserrat/Montserrat-Bold.svg#MontserratBold') format('svg');
			    font-weight: 400;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Montserrat';
			    src: url('../fonts/Montserrat/Montserrat-LightItalic.woff2') format('woff2'),
			         url('../fonts/Montserrat/Montserrat-LightItalic.woff') format('woff'),
			         url('../fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype'),
					 url('../fonts/Montserrat/Montserrat-LightItalic.svg#MontserratLightItalic') format('svg');
			    font-weight: 300;
			    font-style: italic;
			}
		