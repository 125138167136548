
	// Placeholder SASS (ex: @extend %bg-cover;)

		// Centrer verticalement (position absolute)

			%center-vertically {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

		// Background Cover

			%bg-cover {
				background: {
					position: center center;
					repeat: no-repeat;
					size: cover;
				}
			}