
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			font: 300 1.6rem $font-stack-common;
			color: $anaf-gris-texte;

			&.is-fixed {
				width: 100%;
				height: 100%;
				position: fixed;
				overflow: hidden;
			}
		}

		main {
			flex: 1 1 auto;
			overflow: hidden;
		}

		h1 {
			font: 300 3.5rem $font-stack-common;
			color: $black;

			@media (max-width: ($medium)) {
				font-size: 3rem;
			}

			@media (max-width: ($small)) {
				font-size: 2.5rem;
			}
		}

		h2 {
			margin: 0 0 3rem;
			font: 300 3.5rem $font-stack-serif;
			color: $bleu-anaf;
      letter-spacing: -.1rem;

			@media (max-width: ($medium)) {
				font-size: 3.5rem;
			}

			@media (max-width: ($small)) {
				font-size: 3rem;
			}
		}

		h3 {
			margin: 0 0 3rem;
			font: 300 2rem $font-stack-common;
			text-transform: uppercase;
			letter-spacing: .1rem;

			@media (max-width: ($small)) {
				font-size: 1.7rem;
			}
		}

		a {
			outline: none;
		}

    p {
      line-height: 1.6;
    }

		///// Corrections Slick slider

		.slick-list, .slick-track, .slick-slide {
			height: 100%;
		}

		///// Correction frameborder (obsolète)

		iframe {
			border: none;
		}

		///// Iframe responsive fullscreen

		.iframe-wrapper {
			position: relative;
			margin: 3rem 0;
		}

		.iframe-sub-wrapper {
			position: relative;
			height: 0;
			padding: 0 0 56.25%;

		    iframe, embed, object {
				position: absolute;
			    top: 0;
			    left: 0;
			    width: 100% !important;
			    height: 100% !important;
			}
		}

		///// Containers custo

		%container {
      position: relative;
      margin: 0 auto;
      padding: 0 2rem;
    }

    .container {
      max-width: 120rem;
      @extend %container;
    }

    .container-small {
      max-width: 90rem;
      @extend %container;

      @media (max-width: ($tiny)) {
        padding: 0;
      }
    }

    .p-section {
    	padding: 7rem 0;

    	@media (max-width: ($medium)) {
    		padding: 5rem 0;
    	}

    	@media (max-width: ($small)) {
    		padding: 3rem 0;
    	}
    }

    .grey-section {
			background-color: $gris-clair;
		}

		.no-pic-header {
			background-color: $bleu-anaf;

			.container {
				margin-top: 10rem;

				@media (max-width: ($large)) {
					margin-top: 8rem;
				}

				@media (max-width: ($small)) {
					margin-top: 9rem;
				}
			}

			h1 {
				text-transform: uppercase;
				letter-spacing: .2rem;
			}
		}

		///// Style page 404

		.error-404 {
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: calc(100vh - 26.6rem);
			color: $white;
			background: linear-gradient(to bottom, $error 50%, $bleu-anaf 100%);

			h1 {
				margin-bottom: 2rem;
				font: 300 10rem $font-stack-serif;
				color: $white;
			}

			a {
				text-decoration: none;
				font-weight: 400;
				color: $white;
			}
		}

        ////// Style boutons et liens

        .basic-btn {
        	display: table;
        	margin: 3rem auto 0;
        	padding: 1rem;
        	text-decoration: none;
        	text-align: center;
        	letter-spacing: .1rem;
        	font: 300 1.8rem $font-stack-serif;
        	text-transform: uppercase;
        	border-top: .1rem solid $bleu-anaf;
        	border-bottom: .1rem solid $bleu-anaf;
        	color: $bleu-anaf;
        	background-color: transparent;
        	box-shadow: none;
        	transition: all .3s ease-in-out;

        	@media (max-width: ($medium)) {
				font-size: 1.6rem;
			}

			@media (max-width: ($tiny)) {
				margin-top: 2rem;
			}

        	&:hover {
        		text-decoration: none;
        		color: $white;
        		background-color: $bleu-anaf;
        	}
        }

        ///// Style 1er slider

        .section-slider {
        	position: relative;
        }

    .intro-slider, .intro-img {
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				content: '';
				background-color: rgba($black, .5);
				z-index: 1;
			}
		}

		.intro-slider:before {
			bottom: .4rem;
		}

		.intro-img:before {
			bottom: 0;
		}

    .slide, .intro-img, .map {
      width: 100%;
      height: 60vh;

			@media (max-width: $small) {
				height: 40rem;
			}

			@media (max-width: ($tiny)) {
				height: 35rem;
			}
        }

        .slide, .intro-img {
			@extend %bg-cover;
        }

        .slick-dots {
			z-index: 2;
		}

		.wrapper-texte {
			width: 100%;
		}

		.intro-texte {
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;

			h1 {
        font-family: $font-stack-serif;
        font-size: 6rem;
				// font: 300 10rem $font-stack-serif;
				// line-height: 6rem;
        color: $bleu-clair;
				// color: $anaf-bleu-fonce;

				@media (max-width: ($small-plus)) {
					font-size: 7rem;
					line-height: 4rem;
				}

				@media (max-width: ($tiny)) {
					font-size: 5rem;
					line-height: 3rem;
				}

				span {
					&:first-of-type {
						// font-size: 8rem;

						@media (max-width: ($small-plus)) {
							font-size: 3rem;
						}

						@media (max-width: ($tiny)) {
							font-size: 2.5rem;
						}
					}

					&:last-of-type {
						display: block;
						margin-top: 1rem;
						font: 300 3.5rem $font-stack-serif;
						color: $white;

						@media (max-width: ($small-plus)) {
							font-size: 3rem;
						}

						@media (max-width: ($tiny)) {
							font-size: 2.8rem;
						}
					}
				}
			}
		}

		.grey-section .container-small:not(.section-dates) {
			margin-bottom: 4rem;
		}

		.section-dates, .section-presentation {
			ul {
	    		padding-left: 4rem;
	    		list-style-type: circle;
			}

			blockquote {
				margin-bottom: 2rem;

				p {
					font-size: 1.6rem;
				}
			}
		}

		.section-dates {
			p {
				margin: 0 0 3rem 1rem;
				font-size: 1.4rem;

				strong:first-of-type {
					margin-left: -1rem;
				}
			}

			ul {
				margin: -2rem 0 3rem;
				font-size: 1.4rem;
			}
		}
