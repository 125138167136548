// Page Jean-Claude Anaf

	.jca-dates {
		h2 {
			font-size: 5rem;

			@media (max-width: ($medium)) {
				font-size: 4.5rem;
			}

			@media (max-width: ($small)) {
				font-size: 4rem;
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.basic-slider {
		margin-bottom: 3rem;

    	.slick-dots {
    		margin: 0;
	    	padding-left: 0;
	    }
    }